.rodape__creditos{
	display: flex;
	justify-content: space-between;
	text-align: center;
}
.creditos{
	display: flex;
	flex-direction: column;

	.credito__left{
		@include media-breakpoint-down (md) {
			display: flex;
			flex-direction: column;
			align-items: center;
		}
		span{
			font-family: 'baloobold';
		}
	}
}
.rodape__conteudo{
	position: relative;
	padding-top: 40px;
	background-color: #343338;
	color: #fff;
}
.rodape{
	position: relative;
}
.rodape__header{
	position: relative;
	z-index: 3;
	margin-bottom: 10px;

	.titulo__secao{
		color: #fff;
		padding-top: 70px;

		span{
			margin-left: 10px;
		}
	}
}
.rodape__form{
	display: flex;
	justify-content: center;
	border-radius: 37px;
	overflow: hidden;
	background-color: #fff;
}
.segura__rodape__input{
	width: 100%;
	flex-grow: 2;
	padding: 16px 16px;

	&:first-child{
		.rodape__input{
			border-right: 3px solid #343338;
		}
	}
}
.rodape__icone{
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 56px;
	min-width: 56px;
	height: 56px;
	margin-right: 10px;
	z-index: 2;
	background-color: #eb3237;
	border-radius: 50%;
}
.rodape__box{
	display: flex;
	align-items: center;
	margin-bottom: 25px;
}
.rodape__conteudo{
	font-family: 'baloobold';
	font-size: 16px;
	line-height: 18px;
}
.listagem__contato{
	border-bottom: 1px solid #fff;
	position: relative;
	z-index: 2;
	padding-bottom: 10px;
}
.sub__rodape{
	font-family: 'baloobold';
	font-size: 18px;
	line-height: 20px;
	margin-bottom: 16px;
}
.num__rodape{
	font-size: 30px;
	line-height: 34px;
	letter-spacing: -1px;
	font-family: 'balooextrabold';
	color: #fff;
	margin-bottom: 16px;
}
.rodape__titulo{
	font-family: 'balooextrabold';
	font-size: 18px;
	line-height: 20px;
	margin-bottom: 10px;
}
.horario__atendimento{
	padding-top: 15px;
}
.row__baixo{
	padding-bottom: 10px;

	.col-lg-5{
		position: relative;
		z-index: 1;

		&:before{
			width: 122px;
			height: 75px;
			content: '';
			position: absolute;
			bottom: -50px;
			right: -50px;
			background-image: url('../images/nuvem__grande.png');
			background-repeat: no-repeat;
			background-position: center;
			background-size: cover;
			animation: mexer 3s linear infinite;
			z-index: -1;
		}
		&:after{
			content: '';
			position: absolute;
			width: 209px;
			height: 100px;
			left: -200px;
			bottom: 10px;
			z-index: -1;
			background-image: url('../images/duas__nuvens.png');
			background-repeat: no-repeat;
			background-size: cover;
			background-position: center;
			animation: mexer 3s linear infinite;
			z-index: -1;
		}
	}
	.col-lg-7{
		position: relative;
		z-index: 3;

		&:after{
			content: '';
			position: absolute;
			width: 197px;
			height: 192px;
			right: -200px;
			top: -50px;
			background-image: url('../images/duas__nuvens__alt.png');
			background-repeat: no-repeat;
			background-size: cover;
			background-position: center;
			animation: mexer 3s linear infinite;
		}
	}
}
.rodape__menu{
	font-family: 'balooregular';
	font-size: 16px;
	line-height: 18px;
	margin-bottom: 10px;
	display: flex;
	flex-direction: column;
	align-items: flex-start;

	a{
		display: inline-block;
		padding: 3px 0px;
		margin-bottom: 5px;
	}
}
.pagamento__texto{
	text-align: right;
}
.titulo__pagamento{
	font-family: 'baloobold';
	font-size: 16px;
	line-height: 16px;
	color: #fff;
	margin-bottom: 0px;
}
.pagamento__txt{
	font-family: 'balooregular';
	font-size: 16px;
	line-height: 16px;
}
.pagamento__morreu{
	align-items: center;
}
.rodape__creditos{
	padding-top: 24px;
	padding-bottom: 12px;
	font-family: 'balooregular';
	font-size: 13px;
	line-height: 16px;
	align-items: center;
}
.gv8__box{
	display: flex;
	align-items: center;
	font-family: 'balooregular';
	font-size: 13px;
	line-height: 17px;
	justify-content: center;
}
.gv8__logo{
	margin-left: 5px;
}
.sociais__rodape{
	font-size: 18px;
	line-height: 18px;
	display: flex;

	a{
		display: flex;
		align-items: center;
		justify-content: center;
		margin: 0px 3px;
		width: 32px;
		height: 32px;
		background-color: #fff;
		color: #343338;
		border-radius: 50%;

		&:hover{
			text-decoration: none;
		}
	}
}
.gv8__box{
	display: flex;
	align-items: center;
	justify-content: center;

	svg{
		margin-left: 5px;
	}
}
.creditos{
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: flex-start;
}
.rodape__conteudo{
	position: relative;

}
@include media-breakpoint-up (lg) {
	.listagem__contato{
		margin-bottom: 40px;
	}
}
@include media-breakpoint-down (lg) {
	.rodape__creditos{
		padding-bottom: 140px;
	}
	.btn__voltar{
		right: 50%;
		transform: translateX(50%);
	}
}
@include media-breakpoint-down (md) {
	.rodape__box{
		display: flex;
		flex-direction: column;
		align-items: center;
		text-align: center;

		.rodape__icone{
			margin-right: 0px;
			margin-bottom: 10px;
		}
	}
	.rodape__baixo{
		position: relative;
		color: #fff;
	}
	.rodape__cima{
		padding-bottom: 40px;
	}
	.listagem__contato{
		.col-lg-3{
			padding: 0px 5px;
		}
	}
	.row__baixo{
		padding-top: 40px;
		text-align: center;
	}
	.endereco__rodape{
		margin-bottom: 30px;
	}
	.pagamento__txt{
		padding-top: 20px;
	}
	.rodape__menu{
		margin-bottom: 40px;
		align-items: center;
	}
	.creditos{
		order: 2;
	}
	.sociais__rodape{
		order: 1;
		margin-bottom: 20px;
	}
	.gv8__box{
		padding-top: 10px;
	}
	.rodape__titulo{
		padding-top: 20px;
	}
}
@include media-breakpoint-down (sm) {
	.rodape__creditos{
		flex-direction: column;
		align-items: center;
		text-align: center;
	}
	.gv8__box{
		margin-bottom: 20px;
		justify-content: center;
	}
	.rodape__form{
		flex-direction: column;
		align-items: center;
		border-radius: 10px;
		padding: 0px 10px;

		.segura__rodape__input{
			padding: 10px;
			border-bottom: 2px solid #343338;

			&:first-child{
				.rodape__input{
					border-right: 0px;
				}
			}
		}
		.segura__rodape__btn{
			padding: 10px 0px;
		}
	}
	.titulo__fantasma__rdp{
		font-size: 130px;
		line-height: 130px;
	}
}
@include media-breakpoint-down (sm) {
	.titulo__fantasma__rdp{
		font-size: 80px;
		line-height: 80px;
	}
}
.rodape__imagem{
	text-align: center;
	max-width: 1920px;
	width: 100%;
	margin-left: auto;
	margin-right: auto;
	height: 28px;
	position: relative;

	@include media-breakpoint-down (md) {
		display: none;
	}

	img{
		position: absolute;
		top: 0px;
		left: 50%;
		transform: translateX(-50%);
		width: 100%;
		min-width: 1920px;
	}
}
.rodape__item{
	margin-bottom: 15px;
	font-family: 'baloomedium';
}
.pagamento__morreu{
	padding-top: 22px;
}
@keyframes mexerAlt{
	0%{
		transform: translate(0px, 0px) rotate(0deg);
	}
	50%{
		transform: translate(8px, -5px) rotate(-10deg);
	}
	100%{
		transform: translate(0px, 0px) rotate(0deg);
	}
}
