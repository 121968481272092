.modal__custom{
	.modal-dialog{
		max-width: 100%;
		width: 1100px;
	}
	.segura__botao__modal{
		display: flex;
		justify-content: flex-end;
		margin-bottom: 10px;
		padding: 10px;

		button{
			font-size: 20px;
			line-height: 20px;
			display: flex;
			align-items: center;
			justify-content: center;
			width: 40px;
			height: 40px;
			color: #096b42;
			border: 2px solid #096b42;
			border-radius: 50%;
			background-color: transparent;
		}
	}
	.titulo__secao{
		text-align: center;
		margin-bottom: 30px;
	}
	.selecione__grid{
		padding-bottom: 20px;
	}
}
.finalizar__header{
	background-color: #343338;
	padding: 10px 24px;
	color: #fff;
	font-size: 18px;
	line-height: 20px;
	font-family: 'baloobold';

	.fas{
		margin-right: 8px;
	}
}
.finalizar__conteudo{
	padding-left: 15px;
	padding-right: 15px;
	padding-bottom: 15px;
	padding-top: 40px;
	max-width: calc(290px + 30px);
	margin-left: auto;
	margin-right: auto;
}
.finalizar__box{
	min-height: 344px;
	border-right: 2px solid #dbdbdb;
	border-bottom: 2px solid #dbdbdb;
	border-left: 2px solid #dbdbdb;

	&.ultima{
		.finalizar__conteudo{
			padding-right: 0px;
			padding-left: 0px;
			max-width: 100%;
			width: 100%;
		}
		.itens__finalizar{
			max-width: 290px;
			width: 100%;
			margin-left: auto;
			margin-right: auto;
		}
	}
	.item__total{
		color: #eb3237;
		text-align: right;
		max-width: 290px;
		padding-top: 8px;
		padding-bottom: 8px;
		padding-left: 22px;
		padding-right: 22px;
		font-family: 'balooextrabold';
		font-size: 16px;
		line-height: 20px;
		margin-left: auto;
		margin-right: auto;
	}
	.item__btns{
		display: flex;
		align-items: center;
		justify-content: center;
		display: flex;
		max-width: 290px;
		width: 100%;
		margin-left: auto;
		margin-right: auto;
		padding-top: 10px;
	}

	.conteudo__btn{
		min-width: 100%;

		&.voltar__btn{
			min-width: calc(50% - 10px);

			&:nth-child(1) {
				margin-right: 10px;
			}
		}
	}
}
.finalizar__conteudo__titulo{
	font-family: 'balooextrabold';
	font-size: 18px;
	line-height: 22px;
	color: #343338;
	margin-bottom: 10px;
}
.finalizar__conteudo__texto{
	margin-bottom: 35px;
	font-size: 18px;
	line-height: 20px;
	min-height: 120px;
}
.endereco__modal__titulo{
	margin-bottom: 30px;
}
.entrega__prazo{
	text-align: center;
	padding-top: 5px;
}
.esgotado__modal__conteudo{
	.entrega__opcao{
		.entrega__label__custom{
			text-align: left;
		}
	}
}
.entrega__input__custom{
	position: fixed;
	left: -100vw;

	&:checked{
		& ~ .entrega__label__custom{
			&:after{
				opacity: 1;
			}
		}
	}
}
.entrega__opcoes{
	border-top: 2px solid #dbdbdb;
	margin-bottom: 40px;
}
.entrega__label__custom{
	cursor: pointer;
	position: relative;
	padding-left: 44px;
	padding-top: 10px;
	padding-bottom: 10px;
	padding-right: 10px;
	padding-bottom: 10px;
	border-left: 2px solid #dbdbdb;
	border-right: 2px solid #dbdbdb;
	border-bottom: 2px solid #dbdbdb;
	display: block;
	width: 100%;
	margin-bottom: 0px;
	font-size: 18px;
	line-height: 22px;
	color: #343338;

	.entrega__titulo{
		font-family: 'balooextrabold';
		margin-bottom: 5px;
	}

	&:before{
		width: 20px;
		height: 20px;
		border: 2px solid #343338;
		border-radius: 50%;
		content: '';
		position: absolute;
		left: 14px;
		top: 10px;
	}
	&:after{
		width: 12px;
		height: 12px;
		border-radius: 50%;
		background-color: #343338;
		left: 18px;
		top: 14px;
		content: '';
		position: absolute;
		opacity: 0;
	}
}
.itens__finalizar{
	color: #eb3237;
	font-family: 'balooextrabold';
	font-size: 16px;
	line-height: 20px;
	margin-bottom: 44px;
}
.item__subtotal{
	display: flex;
	justify-content:  space-between;
	align-items: center;
	padding: 8px 22px;
	font-size: 16px;
	line-height: 22px;
	color: #343338;
	font-family: 'balooextrabold';
	max-width: 290px;
	width: 100%;
	margin-left: auto;
	margin-right: auto;
}
.item__frete{
	background-color: #e7e7e9;
}
.pagamento__item{
	border-left: 2px solid #c7c7c7;
	border-right: 2px solid #c7c7c7;
	border-bottom: 2px solid #c7c7c7;

	.pagamento__btn{
		border-left: none;
		border-right: none;
		border-bottom: none;

		&[aria-expanded="true"]{
			border-bottom: 2px solid #c7c7c7;
		}
	}
}
.boleto__texto{
	margin-bottom: 30px;
	font-size: 16px;
	line-height: 20px;

	span{
		font-family: 'baloobold';
	}
}
.pagamento__conteudo{
	padding: 20px;
}
.pagamento__titulo,
.pagamento__btn{
	background-color: #ededed;
	border: 2px solid #c7c7c7;
	padding: 12px 30px;
	display: flex;
	align-items: center;
	font-family: 'baloobold';
	font-size: 18px;
	line-height: 22px;
	color: #343338;
	width: 100%;

	.icon{
		margin-right: 15px;
	}
}
.pagamento__btn{
	padding: 25px 25px 25px 70px;
	position: relative;
	border-top: none;

	&[aria-expanded="true"] {
		&:after{
			opacity: 1;
		}
	}

	&:before{
		width: 20px;
		height: 20px;
		border: 2px solid #343338;
		border-radius: 50%;
		content: '';
		position: absolute;
		left: 28px;
		top: 50%;
		transform: translateY(-50%);
	}
	&:after{
		width: 12px;
		height: 12px;
		border-radius: 50%;
		background-color: #343338;
		left: 32px;
		top: 50%;
		transform: translateY(-50%);
		content: '';
		position: absolute;
		opacity: 0;
	}
}
.row__pagamento{
	padding-bottom: 45px;
	margin-bottom: 45px;
	border-bottom: 5px solid #e7e7e9;
}
