@font-face {
    font-family: 'balooextrabold';
    src: url('../fonts/balooextrabold/Baloo2-ExtraBold.eot');
    src: url('../fonts/balooextrabold/Baloo2-ExtraBold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/balooextrabold/Baloo2-ExtraBold.woff2') format('woff2'),
        url('../fonts/balooextrabold/Baloo2-ExtraBold.woff') format('woff'),
        url('../fonts/balooextrabold/Baloo2-ExtraBold.ttf') format('truetype'),
        url('../fonts/balooextrabold/Baloo2-ExtraBold.svg#Baloo2-ExtraBold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}


@font-face {
    font-family: 'baloomedium';
    src: url('../fonts/baloomedium/Baloo2-Medium.eot');
    src: url('../fonts/baloomedium/Baloo2-Medium.eot?#iefix') format('embedded-opentype'),
        url('../fonts/baloomedium/Baloo2-Medium.woff2') format('woff2'),
        url('../fonts/baloomedium/Baloo2-Medium.woff') format('woff'),
        url('../fonts/baloomedium/Baloo2-Medium.ttf') format('truetype'),
        url('../fonts/baloomedium/Baloo2-Medium.svg#Baloo2-Medium') format('svg');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}


@font-face {
    font-family: 'baloosemibold';
    src: url('../fonts/baloosemibold/Baloo2-SemiBold.eot');
    src: url('../fonts/baloosemibold/Baloo2-SemiBold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/baloosemibold/Baloo2-SemiBold.woff2') format('woff2'),
        url('../fonts/baloosemibold/Baloo2-SemiBold.woff') format('woff'),
        url('../fonts/baloosemibold/Baloo2-SemiBold.ttf') format('truetype'),
        url('../fonts/baloosemibold/Baloo2-SemiBold.svg#Baloo2-SemiBold') format('svg');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}


@font-face {
    font-family: 'balooregular';
    src: url('../fonts/balooregular/Baloo2-Regular.eot');
    src: url('../fonts/balooregular/Baloo2-Regular.eot?#iefix') format('embedded-opentype'),
        url('../fonts/balooregular/Baloo2-Regular.woff2') format('woff2'),
        url('../fonts/balooregular/Baloo2-Regular.woff') format('woff'),
        url('../fonts/balooregular/Baloo2-Regular.ttf') format('truetype'),
        url('../fonts/balooregular/Baloo2-Regular.svg#Baloo2-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}


@font-face {
    font-family: 'baloobold';
    src: url('../fonts/baloobold/Baloo2-Bold.eot');
    src: url('../fonts/baloobold/Baloo2-Bold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/baloobold/Baloo2-Bold.woff2') format('woff2'),
        url('../fonts/baloobold/Baloo2-Bold.woff') format('woff'),
        url('../fonts/baloobold/Baloo2-Bold.ttf') format('truetype'),
        url('../fonts/baloobold/Baloo2-Bold.svg#Baloo2-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

