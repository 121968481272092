.segura__carrinho{
	padding-left: 30px;
	padding-right: 30px;
	padding-bottom: 30px;
}
.carrinho__header__titulo{
	font-family: 'baloobold';
	font-size: 30px;
	line-height: 34px;
	color: #343338;
}
.carrinho__titulos__interna{
	margin-bottom: 40px;
	align-items: center;

	.titulo__interna{
		margin-bottom: 0px;

		@include media-breakpoint-down (md){
			margin-bottom: 15px;
		}
	}

	.col-lg-6{
		&:nth-child(2) {
			@include media-breakpoint-up (lg) {
				display: flex;
				justify-content: flex-end;
			}
		}
	}
}
.carrinho__header{
	display: flex;
	justify-content: center;
	font-size: 14px;
	line-height: 18px;
	color: #fff;
	background-color: #343338;
	font-family: 'baloobold';
}
.carrinho__produto{
	padding-left: 28px;
	padding-top: 11px;
	padding-bottom: 10px;
	padding-right: 28px;
}
.carrinho__quantidade{
	padding-left: 28px;
	padding-top: 11px;
	padding-bottom: 10px;
	padding-right: 28px;
	text-align: center;
}
.carrinho__valor__uni{
	padding-left: 28px;
	padding-top: 11px;
	padding-bottom: 10px;
	padding-right: 28px;
	text-align: center;
    font-family: 'baloobold';
}
.carrinho__valor__total{
	padding-left: 28px;
	padding-top: 11px;
	padding-bottom: 10px;
	padding-right: 28px;
	text-align: center;
    font-family: 'baloobold'
}
.carrinho__produto__card{
	display: flex;
	position: relative;
}
.btn__excluir{
	position: absolute;
	top: -8px;
	left: -15px;
	width: 25px;
	height: 25px;
	border-radius: 50%;
	background-color: rgba(#096b42, 0.68);
	z-index: 10;
	border: none;
	font-size: 10px;
	line-height: 10px;
	color: #fff;
	transition: all 0.3s linear;

	&:hover{
		background-color: rgba(#096b42, 1);
		color: #fff;
	}
	&:focus{
		outline: none;
	}

	span{
		padding-top: 2px;
		padding-left: 1px;
	}
}
.carrinho__produto__img{
	margin-right: 20px;
	min-width: 91px;
}
.carrinho__item{
	display: flex;
	font-family: 'baloosemibold';
	border-left: 2px solid #e6e6e6;
	border-right: 2px solid #e6e6e6;


	&:last-child{
		border-bottom: 2px solid #e6e6e6;
	}
}
.carrinho__produto__desc{
    padding-top: 10px;
    font-family: 'baloosemibold';
    color: #343338;
}
.guardar__link{
	font-family: 'baloosemibold';
	font-size: 14px;
	line-height: 16px;
	display: block;
	max-width: 160px;
	color: #343338;
	width: 100%;
	margin-right: auto;
	margin-left: auto;
	padding-top: 10px;

	&:hover{
		text-decoration: none;
	}
}
.input-group{
	display: flex;
    justify-content: center;

    .input-group-append{
         &:nth-child(1){
            button{
                padding-bottom: 5px;
            }
        }
    }
}
.btn__quantidade{
	border: none;
	display: flex;
	width: 34px;
	height: 38px;
	align-items: center;
	justify-content: center;
    background-color: #343338;
	border: 2px solid #343338;
	color: #fff;
	transition: all 0.3s linear;
    font-family: 'balooregular';
    font-size: 20px;
    line-height: 20px;
    padding-top: 2px;

	&:hover{
		text-decoration: none;
		background-color: #096b42;
		color: #fff;
		border: 2px solid #096b42;
	}

	&:focus{
		outline: none;
	}
}
.carrinho__valor__promocao{
	font-size: 14px;
	line-height: 18px;
	margin-right: auto;
	margin-left: auto;
    text-align: left;
    color: #343338;
	text-decoration: line-through;
	font-family: 'baloobold';
}
.carrinho__valor__unidade{
	font-size: 14px;
	line-height: 18px;
	display: flex;
	align-items: flex-end;
	justify-content: flex-start;
	margin-right: auto;
	margin-left: auto;
    font-family: 'baloobold';
    color: #343338;
}
.numero__valor__unidade{
	font-size: 20px;
	line-height: 20px;
	margin-left: 3px;
    font-family: 'baloobold';
    color: #343338;
}
.carrinho__item{
	.carrinho__produto{
		padding-top: 28px;
		padding-bottom: 20px;
		border-right: 4px solid #e6e6e6;
	}
	.carrinho__valor__uni{
		padding-top: 28px;
		padding-bottom: 20px;
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
		border-right: 4px solid #e6e6e6;
	}
	.carrinho__quantidade{
		padding-top: 28px;
		padding-bottom: 20px;
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
		border-right: 4px solid #e6e6e6;
	}
	.carrinho__valor__total{
		padding-top: 28px;
		padding-bottom: 20px;
		display: flex;
		justify-content: center;
		align-items: center;
		font-family: 'balooregular';
		font-size: 20px;
		line-height: 20px;
        flex-direction: column;
        color: #343338;
	}
}
.row__carrinho__baixo{
	display: flex;
	justify-content: space-between;
	padding-top: 30px;
	border-top: 9px solid #f2f2f2;
	margin-top: 50px;
}
// .carrinho__simulacao__btn{
// 	.alt__btn{
// 		background-color: #343338;
// 		color: #fff;

// 		&:hover{
// 			background-color: darken(#343338, 25%);
// 			color: #fff;
// 		}
// 	}
// }
.col__carrinho__simulacao{
	padding-top: 30px;
	padding-left: 48px;
	padding-right: 48px;
	padding-bottom: 20px;
	position: relative;
	border: 2px solid #ededed;
	position: relative;
}
.carrinho__simulacao__desc{
	font-family: 'baloosemibold';
	font-size: 13px;
	line-height: 16px;
	margin-bottom: 15px;
	color: #343338;
}
.segura__unidade__valor{
    color: #343338;
    font-family: 'baloobold';
}
.carrinho__simulacao__form{
	padding-right: 20px;
	width: 100%;
}
.carrinho__simulacao__cep{
	display: flex;
}
.simulacao__cep__input{
	display: block;
	width: 100%;
	height: 48px;
	margin-bottom: 5px;
	padding: 20px 20px 10px 20px;
	font-family: 'balooregular';
	background-color: #ededed;
	color: #343338;
    font-family: 'balooregular';
	font-size: 14px;
	line-height: 18px;
	border-radius: 5px;
	border: none;
	margin-left: 10px;
	border: 2px solid #ededed;

	&:focus{
		outline: none;
	}
}
// .simulacao__btn{
// 	display: flex;
// 	width: 144px;
// 	height: 50px;
// 	border: none;
// 	justify-content: center;
// 	align-items: center;
//     background-color: transparent;
//     border: 5px solid #343338;
//     color: #343338;
//     border-radius: 20px;
//     background-color: transparent;
// 	font-family: 'balooregular';
// 	font-size: 15px;
// 	line-height: 15px;
// 	transition: all 0.3s linear;

// 	&:hover{
// 		background-color: #e4cb79;
// 		text-decoration: none;
//         color: #fff;
//         border: 5px solid #e4cb79;
// 	}

// 	&:focus{
// 		outline: none;
// 	}
// }
.simulacao__cep__link{
    font-family: 'balooregular';
	font-size: 12px;
	line-height: 16px;
    color: #343338;
	text-align: right;
	margin-bottom: 5px;
}
.simulacao__cep__desc{
	font-family: 'baloosemibold';
	font-size: 13px;
	line-height: 16px;
    color: #343338;
    padding-top: 10px;
}
.subtotal__header{
	padding: 20px 25px 10px 20px;
	background-color: #343338;
	color: #fff;
	font-family: 'baloobold';
	font-size: 14px;
	line-height: 18px;
	margin-bottom: 12px;
}
.subtotal__header__item{
	display: flex;
	justify-content: space-between;
	margin-bottom: 5px;
}
.subtotal__total{
    padding: 30px 25px 24px 25px;
	font-family: 'baloobold';
	font-size: 14px;
	line-height: 18px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	background-color: #ededed;
	color: #343338;
}
.subtotal__header__valor{
	font-family: 'balooregular';
}
.subtotal__total__valor{
	font-size: 20px;
	line-height: 24px;
	color: #343338;
	font-family: 'baloobold';
}
.row__carrinho__cupom{
	display: flex;
	justify-content: space-between;
	padding-top: 30px;
	margin-bottom: 30px;
}
.col__cupom{
	display: flex;
	align-items: center;
}
.cupom__input{
	max-width: 340px;
	width: 100%;
	height: auto;
	padding: 12px 18px 10px 26px;
	background-color: #ededed;
	color: #343338;
    font-family: 'balooregular';
	font-size: 14px;
	line-height: 18px;
	border-radius: 5px;
	border: none;
	margin-left: 10px;
	border: 2px solid #ededed;

	&:focus{
		outline: none;
	}

	&::placeholder{
		color: #343338;
	}
}
.cartao__logo{
    width: 21px;
    min-width: 21px;
    height: 14px;
    background-image: url('../images/cartao__logo.png');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
	margin-right: 10px;
}
.cupom__desc{
	font-family: 'balooregular';
	font-size: 12px;
	line-height: 16px;
	color: #343338;
}
.col__carrinho__btn{
	display: flex;
	justify-content: flex-end;
	align-items: center;
}
.alt__btn__2{
	width: 200px;
	height: 50px;
	background-color: transparent;
	color: #fff;
	background-color: #343338;
	font-family: 'baloobold';
	font-size: 14px;
	line-height: 14px;
	padding-top: 2px;
	display: flex;
	align-items: center;
	justify-content: center;
	border: 2px solid #343338;
	transition: all 0.3s linear;
	border-radius: 25px;

	&:hover{
		text-decoration: none;
		background-color: #096b42;
		border: 2px solid #096b42;
		color: #fff;
	}

	&:focus{
		outline: none;
	}
}
.cupom__btn{
	width: 130px;
	height: 50px;
	background-color: transparent;
	color: #fff;
	font-family: 'baloobold';
	font-size: 14px;
	line-height: 14px;
	border-radius: 25px;
	display: flex;
	padding-top: 2px;
	align-items: center;
	justify-content: center;
	border: none;
	margin-right: 10px;
	transition: all 0.3s linear;
	background-color: transparent;
	border: 2px solid #343338;
	background-color: #343338;
	text-align: center;
	border-radius: 25px;

	&:hover{
		text-decoration: none;
		background-color: #096b42;
		border: 2px solid #096b42;
		color: #fff;
	}

	&:focus{
		outline: none;
	}
}
.qtde-toggler{
	display: flex;
	max-width: 100px;
	margin-right: auto;
	margin-left: auto;
	justify-content: center;

	&:after{
		z-index: 20;
		width: 33px;
		height: 40px;
		top: 0px;
		left: 33px;
		position: absolute;
		content: '';
	}

	.input-group-append{
		width: 33%;
		border: none;
	}
	.input__qtd{
		width: 33%;
		text-align: center;
		border: none;
		background-color: #ededed;
        position: relative;
        font-family: 'balooregular';
	}
}
@include media-breakpoint-up (lg) {
	.carrinho__produto{
		width: 38%;
	}
	.carrinho__quantidade{
		width: 23%
	}
	.carrinho__valor__uni{
		width: 22%;
	}
	.carrinho__valor__total{
		width: 17%;
	}
	.col__carrinho__simulacao{
		max-width: 60%;
		width: 100%;
	}
	.col__carrinho__subtotal{
		max-width: 40%;
		width: 100%;
		padding-left: 20px;
	}
	.col__cupom{
		max-width: 60%;
		width: 100%;
	}
	.col__carrinho__btn{
		max-width: 40%;
		width: 100%;
	}
}
@include media-breakpoint-down (md) {
	.carrinho__item{
		flex-direction: column;
	}
	.carrinho__header{
		display: none;
	}
	.carrinho__produto__card{
		flex-direction: column;
		text-align: center;
	}
	.carrinho__produto__img{
		margin-right: 0px;
	}
	.carrinho__item{
		border-right: 1px solid #343338;
		border-bottom: 1px solid #343338;
		border-left: 1px solid #343338;
		padding: 0px;
		margin-bottom: 50px;

		.carrinho__produto,
		.carrinho__quantidade,
		.carrinho__valor__uni,
		.carrinho__valor__total{
			position: relative;
			border: none;
			padding-top: 0px;
			padding-bottom: 10px;
			padding-left: 0px;
			padding-right: 0px;

			&:before{
				content: attr(data-text);
				display: block;
				text-align: center;
				width: 100%;
				padding: 10px 20px;
				color: #fff;
				font-family: 'baloobold';
				font-size: 16px;
				line-height: 20px;
				background-color: #343338343338;
				margin-bottom: 20px;
			}
		}
	}
	.btn__excluir{
		position: absolute;
		left: 30px;
	}
	.row__carrinho__cupom,
	.row__carrinho__baixo{
		flex-direction: column;
	}
	.col__carrinho__btn{
		justify-content: center;
		padding-top: 30px;
	}
	.col__carrinho__simulacao{
		padding-top: 20px;
		padding-left: 15px;
		padding-right: 15px;
		padding-bottom: 20px;
	}
}
.carrinho__simulcao__btn{
	.btn__interna{
		border-radius: 0px;
	}
}
.titulo__interna__carrinho{
	font-family: 'baloobold';
	font-size: 27px;
	line-height: 31px;
	color: #343338;
}
@include media-breakpoint-down (xs) {
	.col__cupom{
		flex-direction: column;
		text-align: center;
	}
	.cartao{
		margin-right: 0px;
		margin-bottom: 10px;
	}
	.cupom__desc{
		max-width: 315px;
		width: 100%;
		margin-bottom: 10px;
	}
	.carrinho__simulacao__cep{
		flex-direction: column;
	}
	.carrinho__simulacao__btn{
		display: flex;
		justify-content: center;
		margin-bottom: 10px;

		.btn__interna{
			border-radius: 0px;
		}
	}
	.simulacao__cep__link{
		margin-bottom: 10px;
	}
	.simulacao__cep__desc{
		text-align: center;
	}
}
.row__pesquisa{
	margin-bottom: 20px;

	.col-lg-3{
		margin-bottom: 30px;
	}
}
.perdeu__texto{
    color: #343338;
    margin-bottom: 20px;
}
.perdeu__btns{
    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap;

    & > * {
        margin: 5px;
    }
}
