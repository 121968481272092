.segura__alterar__form{
    max-width: 920px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
}
.alterar__form{
    max-width: 920px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;

    .form-group{
        margin-bottom: 10px;
    }
}
.form__titulo{
    font-family: 'baloobold';
    font-size: 20px;
    line-height: 24px;
    color: #343338;
    margin-bottom: 16px;
}
.dados__form{
    .form-group{
        margin-bottom: 10px;
    }
}
.contato__input{
    width: 100%;
    display: block;
    height: auto;
    padding: 16px 24px 14px 24px;
    border: none;
    background-color: #ededed;
    font-size: 14px;
    line-height: 16px;
    color: #343338;
    font-family: 'balooregular';
    resize: none;

    &::placeholder{
        color: #343338;
    }
    &:focus{
        outline: none;
    }
}
.alterar__btn{
    display: flex;
    justify-content: flex-end;
    padding-top: 20px;
}
.contato__btn{
    display: flex;
    justify-content: center;
    padding-top: 10px;
}
.dados__btn{
    display: flex;
    justify-content: flex-end;
    padding-top: 15px;
}
.dados__form{
    max-width: 924px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
}
.erro__desc{
    text-align: justify;
    margin-bottom: 30px;
}
.segura__erro{
    max-width: 930px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
}
.erro__botao{
    display: flex;
    justify-content: center;
}
.segura__genero{
    color: #343338;
}
