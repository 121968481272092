.bg__menu{
	position: fixed;
	width: 100%;
	height: 100%;
	left: 0px;
	top: 0px;
	background-color: rgba(#000, 0.6);
	animation: fadeIn 0.5s linear;
	z-index: 1050;

	&.hide{
		animation: fadeOut 0.5s linear;
	}
}
.topo__pesquisa{
    position: fixed;
    top: -150px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1080;
    max-width: 900px;
    width: 90%;
    transition: all 0.5s linear;

    &.shown{
        top: 48vh;
    }
}
.segura__bem__vindo{
	span{
		font-family: 'baloobold';
	}
}
.contato__box{
	display: flex;
	align-items: center;

	.contato__box__icone{
		width: 24px;
		height: 24px;
		background-color: #fff;
		color: #eb3237;
		border-radius: 50%;
		margin-right: 5px;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.contato__box__titulo{
		font-family: 'balooextrabold';
	}
	.contato__box__conteudo{
		display: flex;
		align-items: center;
		font-family: 'baloobold';

		& > * {
			margin-right: 5px;
		}
	}
}
.sociais__topo{
	display: flex;
	align-items: center;
	justify-content: center;

	a{
		width: 24px;
		height: 24px;
		display: flex;
		align-items: center;
		justify-content: center;
		background-color: #fff;
		color: #eb3237;
		border-radius: 50%;
		margin: 0px 3px;

		&:hover{
			text-decoration: none;
			color: #eb3237;
			box-shadow: 0px 0px 0px 3px rgba(#fff, 0.5);
		}
	}
}
.pesquisa__form{
    display: flex;
    overflow: hidden;
    background-color: #ededed;
	color: #343338;
    align-items: center;
	border-radius: 40px;

    .pesquisa__btn{
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #eb3237;
        color: #fff;
        min-width: 47px;
        width: 50px;
        height: 50px;
        font-size: 18px;
        line-height: 18px;
        border: none;
		border-radius: 50%;
		position: relative;
    }
    .pesquisa__input{
        display: block;
        width: 100%;
        font-family: 'baloomedium';
        font-size: 14px;
        line-height: 16px;
        padding: 19px 20px 15px 20px;
        border: none;
        background-color: #ededed;
        color: #343338;
        border: none;
        border-radius: 45px;

        &::placeholder{
            color: #343338;
        }

        &:focus{
            outline: none;
        }
    }
    label{
        display: block;
        width: 100%;
        margin-bottom: 0px;
    }
}
.main__pesquisa{
	flex-grow: 1;
	max-width: 450px;
}
.segura__login__topo{
    font-family: 'baloobold';
	font-size: 16px;
	line-height: 18px;
	color: #343338;
	position: relative;
	text-align: right;

	.login__icone{
		margin-left: 10px;
	}

    .login__toggler{
        display: flex;
        align-items: center;

        &:hover{
            text-decoration: none;
        }
    }
    .dropdown{
        display: none;
    }

    &.logado{
        .login__toggler{
            display: none;
        }
        .dropdown{
            display: block;
        }
    }
}
.carrinho__link__topo{
	display: flex;
	align-items: center;
	text-align: right;
	font-size: 16px;
	line-height: 18px;
	color: #343338;
	font-family: 'baloobold';

	.carrinho__link__topo{
		margin-left: 10px;
	}
}
.logado__toggler{
	display: flex;
	align-items: center;
	border: none;
	background-color: transparent;
	font-family: 'baloobold';
	font-size: 16px;
	line-height: 18px;
	color: #343338;
	padding: 5px 0px;
	text-align: right;

	&:focus{
		outline: none;
	}
}
.encerrar__item{
    padding: 10px 10px;
    display: flex;
    align-items: center;
    justify-content: center;

    .encerrar__link{
        width: 198px;
        max-width: 100%;
        height: 50px;
        background-color: #343338;
        color: #fff;
        font-family: 'baloobold';
        font-size: 14px;
        line-height: 14px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 25px;
        transition: all 0.3s linear;
		padding-top: 2px;

		@include media-breakpoint-down(md) {
            height: 28px;
        }

        &:hover{
            background-color: #096b42;
            color: #fff;
            text-decoration: none;
        }
    }
}
.categoria__expanded,
.mobile__categorias{
	background-color: #343338;

	.categoria__item{
		a{
			display: block;
			padding: 10px;
			border-bottom: 1px solid #fff;
			background-color: #343338;
			font-family: 'baloosemibold';
			color: #fff;
			transition: all 0.5s linear;
			font-size: 14px;
			line-height: 18px;

			&:hover{
				color: #fff;
				text-decoration: none;
				background-color: darken(#343338, 10%);
			}
		}
	}
	.dropdown__controls{
		position: relative;

		button{
			width: 20px;
			height: 20px;
			position: absolute;
			top: calc(50% - 10px);
			right: 10px;
			background-color: #fff;
			color: #000;
			display: flex;
			align-items: center;
			justify-content: center;
			font-size: 12px;
			line-height: 12px;
			border-radius: 50%;
			border: 1px solid #000;
			transition: all 0.3s linear;
			padding: 0px;
			padding-top: 2px;

			&[aria-expanded="true"] {
				transform: rotate(90deg);
			}
		}
	}
	.dropmenu{
		list-style: none;
		padding-left: 0px;
		margin-bottom: 0px;

		// li{
		// 	border-bottom: 1px solid #fff;
		// }

		a{
			display: block;
			width: 100%;
			padding: 10px;
			background-color: #eb3237;
			color: #fff;
			border-bottom: 1px solid #fff;

			&:hover{
				color: #fff;
				text-decoration: none;
				background-color: lighten(#eb3237, 10%);
			}
		}
	}
}
.btn__toggle__categ{
	background-color: transparent;
	border: none;
	padding: 5px;
	display: flex;
	align-items: center;
	justify-content: center;
	color: #fff;
	min-width: 200px;
	min-height: 60px;

	&:before{
		width: 27px;
		height: 23px;
		background-image: url(../images/burgue.png);
		background-position: center center;
		background-size: auto;
		background-repeat: no-repeat;
		display: block;
		content: '';
		margin-right: 10px;
	}
}
.logado__nome{
    padding: 28px 20px 22px 20px;
    background-color: #096b42;
    color: #fff;
    font-family: 'balooregular';
    font-size: 13px;
    line-height: 16px;

	@include media-breakpoint-down (md) {
        padding: 12px 6px 10px 6px;
    }
}
.logado__item{
    padding: 0px 10px;
	background-color: #fff;

    .logado__link{
        display: block;
        padding: 22px 10px 20px 10px;
        border-bottom: 1px solid rgba(#000, 0.1);
        font-size: 13px;
        line-height: 15px;
        font-family: 'baloobold';
        color: #000;

        &:hover{
            font-family: 'baloobold';
            color: #096b42;
			text-decoration: underline;
        }

		@include media-breakpoint-down (md) {
            padding: 10px 6px 10px 6px;
        }
    }
}
.categoria__destaques,
.categoria__extras{
	padding-left: 0px;
	list-style: none;
	margin-bottom: 0px;
}
.dropdown-menu{
	border: none;
	padding: 0px;
	margin: 0px;
}
@include media-breakpoint-up (lg) {
	.logado__menu{
		min-width: 256px;
        max-width: 100%;
        left: calc(50% - 128px) !important;
        border-radius: 0px;
        border: none;
        padding: 0px;
        box-shadow: 0px 6px 10px rgba(#000, 0.6);
		top: 15px !important;
	}
	.logado__toggler{
		position: relative;

        &:after{
            border-left: 14px solid transparent;
            border-right: 14px solid transparent;
            border-bottom: 15px solid #096b42;
            content: '';
            position: absolute;
            left: calc(50% - 14px);
            bottom: -18px;
            display: none;
			z-index: 100;
        }

        &[aria-expanded="true"] {
            &:after{
                display: block;
            }
        }
	}
	.desktop__categorias{
		display: flex;
		align-items: center;
	}
	.login__icone,
	.carrinho__icone{
		width: 50px;
		height: 50px;
		background-color: #eb3237;
		color: #fff;
		border-radius: 50%;
		display: flex;
		align-items: center;
		justify-content: center;
		margin-left: 10px;
	}
	.carrinho__link__topo{
		margin-left: 15px;
	}
	.main__cima{
		background-color: #eb3237;
		color: #fff;
		font-size: 16px;
		line-height: 20px;
		padding: 6px 0px;

		.container{
			display: flex;
			align-items: center;
			justify-content: space-between;
		}
	}
	.contato__topo{
		display: flex;
		align-items: center;

		.contato__box{
			margin-right: 10px;
		}
	}
	.main__right{
		display: flex;
		align-items: center;
	}
	.main__segura{
		display: flex;
		align-items: center;
	}
	.main__meio{
		.container{
			display: flex;
			align-items: center;
			justify-content: space-between;
		}
	}
	.categoria__expanded{
		position: fixed;
		z-index: 1080;
		top: 0px;
		width: 250px;
		height: 100%;
		overflow-y: auto;
		left: -250px;
		transition: all 0.5s linear;

		&.shown{
			left: 0px;
		}
	}
	.topo__mobile,
	.topo__pesquisa{
		display:none;
	}
	.mobile__categorias{
		display:none;
	}
	.mobile__icone{
		display: none !important;
	}
	.main__categoria{
		.container{
			margin-bottom: -26px;
			background-color: #343338;
			color: #fff;
			font-family: 'baloobold';
			font-size: 16px;
			line-height: 18px;

			.categoria__destaques{
				font-family: 'baloomedium';
				display: flex;
				align-items: center;
				justify-content: space-between;
				flex-grow: 1;
				width: 100%;
			}
		}
	}
	.categorias__destaques{
		flex-grow: 1;
		width: 100%;
	}

	.categoria__destaques{
		display: flex;
		justify-content: space-between;
		width: 100%;
		padding-left: 30px;

		.categoria__item{
			position: relative;

			button{
				display: none;
			}

			a{
				height: 60px;
				display: flex;
				align-items: center;
				justify-content: center;
				padding: 0px 5px;
				text-align: center;
			}
			&:hover{
				.dropmenu{
					display: block;
				}
			}
		}

		.dropmenu{
			position: absolute;
			top: 100% !important;
			left: calc(50% - 128px);
			width: 256px;
			max-height: 300px;
			overflow-y: auto;
			background-color: #fff;
			list-style: none;
			padding-left: 0px;
			margin-bottom: 0px;
			box-shadow: 0px 0px 8px rgba(#000, 0.4);

			li{
				display: flex;

				&:last-child{
					a{
						border-bottom: none;
					}
				}

				a{
					display: flex;
					align-items: center;
					justify-content: center;
					padding: 10px;
					width: 100%;
					height: unset;
					min-height: unset;
					text-align: center;
					color: #000;
					transition: all 0.3s linear;
					font-family: 'baloomedium';
					border-bottom: 1px solid rgba(#000, 40%);

					&:after,
					&:before{
						display: none;
					}

					&:hover{
						text-decoration: none;
						color: #fff;
						background-color: #dd1818;
					}
				}
			}
		}
	}
	.main__logo{
		padding: 10px 0px;
	}
	.main__meio{
		background-color: #fff;
	}
}
@include media-breakpoint-only (lg) {
	.main__logo{
		max-width: 200px;
	}
	.main__pesquisa{
		padding: 0px 15px;
	}
	.contato__box{
		.contato__box__conteudo{
			flex-direction: column;
			align-items: flex-start;
		}
	}
	.btn__toggle__categ{
		&:before{
			display: none;
		}
		min-width: 100px;
	}
}
@include media-breakpoint-down (md) {
	.main__logo{
		padding: 10px;
	}
	.topo__main{
		position: fixed;
		top: 0px;
		left: -250px;
		width: 250px;
		z-index: 1080;
		height: 100%;
		overflow-y: auto;
		transition: all 0.5s linear;
		flex-direction: column;
		display: flex;
		background-color: #fff;

		.sociais__topo{
			padding: 10px 0px 20px 0;

			a{
				background-color: #eb3237;
				color: #fff;

				&:hover{
					background-color: #343338;
					color: #fff;
				}
			}
		}

		.main__meio{
			order: 1;
		}
		.main__categoria{
			order: 3;
		}
		.main__cima{
			order: 2;

			.segura__bem__vindo{
				display: none;
			}
			.contato__box{
				flex-direction: column;
				align-items: center;
				margin-bottom: 15px;

				.contato__box__conteudo{
					flex-direction: column;
				}
			}
		}

		.container{
			padding: 0px;
		}

		&.shown{
			left: 0px;
		}
		.segura__login__topo{
			display: none;
		}
		.segura__carrinho__topo{
			display: none;
		}
	}
	.desktop__categorias{
		.btn__toggle__categ{
			display: none;
		}
		.dropdown__controls{
			position: relative;

			button{
				width: 20px;
				height: 20px;
				position: absolute;
				top: calc(50% - 10px);
				right: 10px;
				border: none;
				background-color: #fff;
				color: #343338;
				border-radius: 10px;
				padding: 3px 5px 1px 5px;
				transition: all 0.3s linear;

				&[aria-expanded="true"]{
					transform: rotate(90deg);
				}
			}
		}
		.categoria__extras{
			.dropdown__controls{
				button{
					background-color: #343338;
					color: #fff;
				}
			}
		}
	}
	.topo__mobile{
		border-bottom: 3px solid #343338;

		.container{
			display: grid;
			grid-template-columns: 1fr minmax(auto, 200px) 1fr;
			padding: 5px 0px;
		}
	}
	.mbl__logo{
		display: flex;
		align-items: center;
		justify-content: center;
		max-width: 150px;
	}
	.mbl__toggler{
		border: none;
		background-color: transparent;
		font-size: 24px;
		line-height: 24px;
		background-color: transparent;
		color: #343338;

		&:focus{
			outline: none;
		}
	}
	.main__logo{
		padding: 10px;
		text-align: center;
		margin-bottom: 10px;
	}
	.segura__contato{
		display: flex;
		flex-direction: column;
		align-items: center;
		text-align: center;
		margin-bottom: 20px;

		span{
			margin-bottom: 5px;
		}

		.barrinha{
			display: none;
		}
	}
	.main__pesquisa{
		form{
			display: none;
		}
	}
	.login__toggler,
	.logado__toggler{
		display: flex;
		align-items: center;
		text-align: center;
		justify-content: center;
		padding: 10px;
		width: 100%;
	}
	.dropdown{
		width: 100%;
		display: flex;
		flex-direction: column;
	}
	.logado__menu{
        min-width: 200px;
        max-width: 100%;
        border-radius: 0px;
        border: none;
        padding: 0px;
        box-shadow: 0px 6px 10px rgba(#000, 0.6);
        left: -3px !important;

        .logado__link{
            font-family: 'baloobold';
        }
    }
	.login__icone,
	.carrinho__icone{
		margin: 5px;
	}
	.btn__toggle__categ{
		background-color: #343338;
		color: #fff;
		padding: 10px 20px;
		width: 100%;
		display: flex;
		text-align: center;
		border-bottom: 1px solid #fff;
		min-height: unset;
		font-family: 'baloobold';

		 &:after{
			 display: block;
			 border-left: 7px solid transparent;
			 border-right: 7px solid transparent;
			 border-top: 10px solid #fff;
			 content: '';
			 margin-left: 10px;
		 }
	}
	.segura__carrinho__topo{
		padding: 15px 0px;
		display: flex;
		justify-content: center;
	}
	.logado__menu{
		border-bottom: 2px solid #e6d9d8;
		box-shadow: 0px 6px 10px rgba(#000, 0.6);
	}
	.btn__toggle__categ{
		&:before{
			display: none;
		}
	}
	.segura__bem__vindo{
		padding: 10px;
		background-color: #dd1818;
		color: #fff;
		text-align: center;
	}
	.btn__left__topo{
		display: flex;
		align-items: center;
		justify-content: flex-start;

		.mobile__login{
			margin-left: 10px;

			.segura__login__topo{
				.login__toggler,
				.logado__toggler{
					padding: 15px 0px;
					border: none;

					a{
						&:hover{
							color: #343338;
							text-decoration:none;
						}
					}

					&[aria-expanded="true"] {
						&:after{
							display: block;
						}
					}

					&:after{
						border-left: 14px solid transparent;
						border-right: 14px solid transparent;
						border-bottom: 15px solid #096b42;
						content: '';
						position: absolute;
						left: -3px !important;
						bottom: 0px;
						display: none;
						z-index: 100;
					}

					.login__texto{
						display: none;
					}
					.login__icone{
						display: none;
					}
				}
			}
		}
	}
	.btn__right__topo{
		display: flex;
		align-items: center;
		justify-content: flex-end;

		.mobile__carrinho{

			a{
				&:hover{
					text-decoration: none;
				}
			}

			.mobile__icone{
				position: relative;
				display: flex;
				align-items: center;
				margin-right: 10px;
				span{
					font-size: 20px;
					line-height: 20px;
					order: 1;
				}
			}

			.carrinho__link__topo{
				.carrinho__icone,
				.carrinho__topo__conteudo{
					display: none;
				}
			}
		}
	}
	.desktop__categorias{
		display: none;
	}
}
