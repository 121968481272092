.banner__carousel{
	max-width: 1920px;
	width: 100%;
	overflow: hidden;
	margin-left: auto;
	margin-right: auto;

	.carousel-inner{
		max-width: 1920px;
		width: 160%;
		position: relative;
		left: 50%;
		transform: translateX(-50%);
	}
	.carousel-indicators{
		justify-content: center;

		li{
			width: 22px;
			height: 22px;
			background-color: transparent;
			border: none;
			position: relative;
			opacity: 1;

			&.active{
				&:after {
					opacity: 1;
				}
			}

			&:after{
				width: 100%;
				height: 100%;
				border: 2px solid #fff;
				border-radius: 50%;
				content: '';
				position: absolute;
				left: 0;
				top: 0;
				opacity: 0;
				transition: all 0.3s linear;
			}
			&:before{
				content: '';
				position: absolute;
				width: 12px;
				height: 12px;
				border-radius: 50%;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
				background-color: #fff;
			}
		}
	}
}
.selecione{
	padding: 55px 0px;
}
.selecione__grid{
	display: grid;
	grid-template-columns: repeat(6, 1fr);
	column-gap: 20px;
	row-gap: 20px;
	justify-content: center;
	align-items: center;

	@include media-breakpoint-down (md) {
		grid-template-columns: repeat(4, 1fr);
	}
	@include media-breakpoint-down (sm) {
		grid-template-columns: repeat(3, 1fr);
	}
	@include media-breakpoint-down (xs) {
		grid-template-columns: repeat(2, 1fr);
	}
}
.selecione__card{
	display: block;
	width: 100%;
	max-width: 150px;
	border-radius: 50%;
	box-shadow: 0px 0px 15px rgba(#000, 0.3);
	display: flex;
	align-items: center;
	justify-content: center;
	border: 4px solid transparent;
	transition: all 0.3s linear;
	margin-left: auto;
	margin-right: auto;

	&:hover{
		border: 4px solid #096b42;
	}

	img{
		border-radius: 50%;
	}
}
.selecione__header{
	text-align: center;
	min-height: 90px;
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
	background-image: url('../images/ghost__title.png');
	background-repeat: no-repeat;
	background-position: center center;
	background-size: 100% auto;
	margin-bottom: 30px;
	max-width: 613px;
	margin-left: auto;
	margin-right: auto;
}
.titulo__secao{
	font-family: 'balooextrabold';
	font-size: 45px;
	line-height: 49px;
	color: #343338;
}
.onde__estamos__header{
	min-height: 84px;
	text-align: center;
	background-image: url('../images/ghost__title2.png');
	background-repeat: no-repeat;
	background-position: center center;
	background-size: 100% auto;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-end;
	margin-bottom: 10px;
	max-width: 324px;
	margin-left: auto;
	margin-right: auto;
}
.onde{
	background-color: #ededed;

	.row{
		align-items: center;
	}

	@include media-breakpoint-up (lg) {
		.onde__imagem{
			min-height: 641px;
			position: relative;

			img{
				position: absolute;
				min-width: 930px;
				right: 0px;
			}
		}
	}
	.calendario__item{
		display: flex;
		align-items: center;
		justify-content: space-between;

		& > * {
			padding: 5px;
			font-family: 'baloomedium';
			font-size: 18px;
			line-height: 22px;
			color: #343338;
		}

		.data__calendario{
			width: 26%;
		}
		.escola__calendario{
			width: 40%;
		}
		.horario__calendario{
			width: 34%;
		}
	}
	.calendario__header{

		& > * {
			color: #eb3237;
			font-family: 'balooextrabold';
			font-size: 18px;
			line-height: 20px;
		}
	}
}
.calendario__listagem{
	max-height: 260px;
	height: 100%;
	overflow-y: auto;
}
.onde__estamos__calendario{
	max-width: 600px;
	height: 456px;
	width: 100%;
	margin-left: auto;
	margin-right: auto;
	background-image: url('../images/quadro.png');
	background-repeat: no-repeat;
	background-size: 100%;
	background-position: center center;
	padding-top: 110px;
	padding-left: 20px;
	padding-right: 20px;
	padding-bottom: 50px;

	@include media-breakpoint-down (sm) {
		padding: 10px !important;
		background-image: unset;
		border: 5px solid #5e3e2e;
		margin-bottom: 40px;

		.calendario__listagem{
			max-height: unset !important;
		}
	}

	@media (max-width: 420px) {
		height: unset;
		.calendario__header{
			display: none;
		}
		.calendario__item{
			flex-direction: column;
			align-items: center;
			border-bottom: 2px solid #5e3e2e;
			padding-top: 10px;
			padding-bottom: 10px;

			.data__calendario{
				font-family: 'baloobold';
				color: #eb3237;
			}

			& > *{
				width: 100% !important;
				text-align: center;
			}
		}

	}
}
.sub__secao{
	font-size: 25px;
	line-height: 29px;
	color: #343338;
}
.disponibilidade{
	padding: 70px 0px 40px 0px;
}
.header__disponibilidade{
	min-height: 83px;
	background-image: url('../images/ghost__title3.png');
	background-repeat: no-repeat;
	background-size: 100% auto;
	background-position: center center;
	display: flex;
	text-align: center;
	justify-content: center;
	align-items: center;
	margin-bottom: 30px;
	max-width: 822px;
	margin-left: auto;
	margin-right: auto;
}
.loja__categoria{
	font-family: 'balooextrabold';
	font-size: 18px;
	line-height: 22px;
	text-align: center;
	color: #eb3237;
	margin-bottom: 6px;
}
.loja__endereco{
	font-family: 'baloomedium';
	font-size: 18px;
	line-height: 22px;
	text-align: center;
	color: #eb3237;
	margin-bottom: 6px;
}
.loja__horario__titulo{
	font-family: 'baloobold';
	font-size: 18px;
	line-height: 22px;
	text-align: center;
	color: #343338;
	margin-bottom: 6px;
}
.loja__horario{
	text-align: center;
	font-size: 18px;
	line-height: 22px;
}
@include media-breakpoint-down (md) {
	.onde{
		.col-lg-6{
			&:nth-child(1) {
				order: 2;
			}
			&:nth-child(2) {
				order: 1;
			}
		}
	}
	.disponibilidade{
		.col-lg-4{
			margin-bottom: 30px;
		}
	}
}
.produto__card{
	max-width: 256px;
	width: 100%;
	margin-left: auto;
	margin-right: auto;
	display: block;
	margin-bottom: 30px;

	&:hover{
		a{
			text-decoration: none;

			.produto__card__titulo{
				color: #096b42;
			}
		}
	}
	a{
		&:hover{
			text-decoration: none;
		}
	}

	.produto__card__imagem{
		border: 1px solid #e4e4e4;
		position: relative;
		border-radius: 30px;
		margin-bottom: 10px;

		.produto__card__tags{
			position: absolute;
			top: 15px;
			right: 15px;
			display: flex;
			flex-direction: column;
			align-items: center;

			& > *{
				width: 46px;
				height: 46px;
				background-color: #096b42;
				color: #fff;
				display: flex;
				align-items: center;
				justify-content: center;
				border-radius: 50%;
				font-family: 'baloobold';
				text-align: center;
				font-size: 16px;
				line-height: 16px;
				margin: 3px;
			}
		}
	}
	.produto__card__titulo{
		font-size: 20px;
		line-height: 24px;
		color: #343338;
		min-height: 48px;
		margin-bottom: 5px;
		text-align: center;
		font-family: 'baloobold';
		transition: all 0.3s linear;
	}
	.produto__card__valor{
		display: flex;
		justify-content: center;
		align-items: center;
		font-size: 18px;
		line-height: 20px;
		color: #343338;
		margin-bottom: 5px;

		.produto__valor__promocao{
			text-decoration: line-through;
		}

		.produto__valor__total{
			font-family: 'baloobold';
			font-size: 28px;
			line-height: 30px;
		}

		&.space-between{
			justify-content: space-between !important;
		}
	}
}
.carrinho__card__botao{
	width: 100%;
	height: 50px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 5px 10px 5px 20px;
	font-family: 'baloobold';
	font-size: 15px;
	line-height: 17px;
	background-color: #343338;
	border: none;
	color: #fff;
	border-radius: 25px;

	&:hover{
		background-color: #096b42;

		.carrinho__icone__bolinha{
			color: #096b42;
		}
	}

	.carrinho__icone__bolinha{
		width: 38px;
		height: 38px;
		content: '';
		display: flex;
		align-items: center;
		justify-content:center;
		background-color: #fff;
		border-radius: 50%;
		color: #343338;
	}
}
