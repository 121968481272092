.row__form{
    max-width: 920px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
}
.login__form,
.login--cadastro__form{
    .form-group{
        margin-bottom: 10px;
    }
}
.titulo__interna__login{
	font-family: 'balooextrabold';
	font-size: 20px;
	line-height: 24px;
	margin-bottom: 46px;
}
.login__desc{
    margin-bottom: 14px;
    padding-top: 5px;
    font-family: 'baloosemibold';
    color: #343338;
    padding-left: 30px;
}
.esqueci__link{
    font-family: 'balooregular';
    font-size: 13px;
    line-height: 15px;
    color: #343338;
    padding-top: 7px;
    display: inline-flex;
    position: relative;
    align-items: center;
    transition: all 0.3s linear;
}
.login__btn{
    padding-top: 5px;
}
.login--cadastro__btn{
    padding-top: 27px;
}
.titulo__login{
    font-family: 'baloobold';
    color: #343338;
    font-size: 30px;
    line-height: 34px;
    margin-bottom: 22px;
}
@include media-breakpoint-down (md) {
    .login__btn{
        margin-bottom: 40px;
    }
}
