.paginacao{
	display: flex;
	justify-content: flex-end;
    flex-wrap: wrap;
    border-radius: none;
    border: none;
    list-style: none;
    padding-left: 0px;
	padding-top: 30px;

	.paginacao__item{
		a{
            font-family: 'balooextrabold';
            font-size: 16px;
            line-height: 16px;
            color: #343338;
            display: flex;
            width: 32px;
            border-radius: 50%;
            align-items: center;
            justify-content: center;
            height: 32px;
            border-radius: 50%;
            position: relative;
            transition: all 0.3s linear;
			border: none;
            margin: 0px 2px;
            padding: 0px;
            padding-left: 1px;
            padding-top: 1px;

            &:focus{
                box-shadow: none;
            }
        }

        &.active{
           a{
                background-color: #096b42;
                color: #fff;
                text-decoration: underline;
           }
        }

		&:hover{
			a{
                text-decoration: underline;
            }
		}
	}
}
